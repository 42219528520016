angular.module("app")
    .controller("asideCropMonitorController", function($timeout, $scope, $rootScope, $uibModal, $filter, _mapFieldService, _view, _v, _apiService, _flashService, Notification,
                                                       _i18n, _mapAddTaskService, $q, gettextCatalog, _chartService, _logicService, _calendar, _formatService, _taskService,
                                                       _appStorage) {

        const GRAPH_X1 = 1,
              GRAPH_X2 = 2,
              GRAPH_HALF = 0.5;

        $scope.$on('_view:urlReady', function() {
            if (_view.is.weather() && _view.is.viewCropMonitor()) {
                initViewCropMonitor();
            }
        });

        $scope.$on('_view:viewCropMonitorChanged', function(event, args) {
            clearViewCropMonitorWhenTasksWereSwitched(args.tasks);
        });

        $scope.$on('_view:addCropMonitorChanged', function(event, args) {
            clearAddCropMonitorWhenTasksWereSwitched(args.tasks);
        });

        $scope.$on('_view:closeAllScreens', function () {
            if (_view.is.viewCropMonitor()) clearViewCropMonitor();
            if (_view.is.addCropMonitor()) clearAddCropMonitor();
        });

        $scope.$on('_map:mapReady', function() {
            if (_view.is.viewCropMonitor()) {
                initViewCropMonitor();
            } else if (_view.is.addCropMonitor()) {
                initAddCropMonitor();
            }
        });

        $scope.$on('_view:updateViewCropMonitor', function () {
            _mapAddTaskService.clearCropMonitor();
            initViewCropMonitor();
        });

        $scope.addCMModel = {};

        function initAddCropMonitor() {
            if (_view.is.addCropMonitor()) {

                document.querySelector('body').onkeydown = function (e) {
                    if (e.keyCode == 27) {
                        document.querySelector('body').onkeydown = null;
                        $rootScope.$safeApply(function() {
                            $scope.closeAddCropMonitor();
                        });
                    }
                };

                $scope.isLoading = true;
                $scope.activeSampling = 50;
                $scope.activeGrid = 0; // index of 'none' grid tab

                var taskDefaults = {
                    title: '',
                    titleData: {
                        type: 'CM',
                        pointsAmount: null,
                    },
                    datePublished: {
                        date: null,
                        initDate: null,
                        inlineOptions: {
                            startingDay: _view.getCurrentAccountInfo().settings.firstDayOfWeek || 1
                            //,
                            //minDate: moment.utc().startOf('day').toDate()
                        }
                    },
                    bandWidth: 30,
                    columns: 5,
                    dividers: 2,
                    gridType: 'none',
                    combineGrid: 1,
                    alongBands: false,
                    standActivitiesPhotosNumber: 1,
                    standRowLength: 1
                };

                $scope.addCMModel.fieldId = (_view.isFieldSelected() || _view.isFarmAndFieldSelected()) ? _view.getCurrentField().id : _view.getPanelField().id;

                _mapFieldService.panToFields([{id: $scope.addCMModel.fieldId}]);
                _mapFieldService.hideAllFieldsExceptOne($scope.addCMModel.fieldId);

                $scope.addCMModel.accountId = _view.getCurrentAccount();
                $scope.addCMModel.dateFormat = _formatService.getDatePickerFormatForDate();
                $scope.addCMModel.isTitleEdited = false;

                $scope.addCMModel.task = _.clone(taskDefaults);

                $scope.addCMModel.freeActivities = {
                    STAND: false,
                    DISEASES: false,
                    INSECTS: false,
                    FENOLOGY: false,
                    WEEDS: false,
                    NOTE: false
                };

                $scope.addCMModel.skipPhotoActivities = {
                    STAND: false,
                    DISEASES: false,
                    INSECTS: false,
                    FENOLOGY: false,
                    WEEDS: false,
                    NOTE: false
                };

                $scope.PAInsects = [];
                $scope.PADiseases = [];
                $scope.addCMModel.task.plantsNumber = 7;

                $scope.addCMModel.samplingType = "REGULAR";
                $scope.addCMModel.samplingTypeSelect = function (type) {
                    $scope.addCMModel.samplingType = type;
                };

                $scope.addCMModel.gridTypeSelect = function(gridType) {
                    $scope.addCMModel.grid = null;
                    $scope.addCMModel.task.alongBands = false;
                    $scope.addCMModel.task.selectedTrackOnly = false;
                    $scope.addCMModel.task.gridType = gridType;

                    _mapAddTaskService.switchMode();

                    if ($scope.addCMModel.task.gridType == 'new') {
                        _mapAddTaskService.editGrid($scope.addCMModel.fieldId, $scope.addCMModel.task.bandWidth,
                            $scope.addCMModel.task.columns, _view.getCurrentUser().units,
                            $scope.addCMModel.task.combineGrid, $scope.addCMModel.task.alongBands);
                    } else if ($scope.addCMModel.task.gridType == 'spray') {
                        $scope.addCMModel.sprayTrack = $scope.addCMModel.sprayTracks[0];
                        $scope.addCMModel.sprayTrackChange();
                    }  else if ($scope.addCMModel.task.gridType == 'cm') {
                        $scope.addCMModel.cropMonitor = $scope.addCMModel.cropMonitors[0];
                        $scope.addCMModel.cropMonitorChange();
                    } else if ($scope.addCMModel.task.gridType == 'none') {
                        //create default hidden grid
                        //turn on add-by-click point mode
                        $scope.addCMModel.task.pointMode = null;
                        $scope.addCMModel.task.combineGrid = 1;
                        $scope.addCMModel.manualPoints = _mapAddTaskService.getManualPoints();

                        $scope.addCMModel.pointModeSwitch('add');
                    }
                };

                $scope.addCMModel.pointModeSwitch = function(mode) {
                    if (mode == $scope.addCMModel.task.pointMode) { //prevent double init
                        return;
                    }
                    $scope.addCMModel.task.pointMode = mode;
                    if ($scope.addCMModel.task.pointMode == 'add') {
                        _mapAddTaskService.addScoutPoints();
                    }
                    if ($scope.addCMModel.task.pointMode == 'edit') {
                        _mapAddTaskService.editScoutPoints();
                    }
                };

                $scope.addCMModel.fieldIsSimple = _mapFieldService.isSimple($scope.addCMModel.fieldId);
                if ($scope.addCMModel.fieldIsSimple) {
                    $scope.addCMModel.gridTypeSelect($scope.addCMModel.task.gridType);
                }

                $scope.addCMModel.gridParamsEdit = function(form) {
                    if (form.bandWidth.$valid && form.gridColumns.$valid) {
                        _mapAddTaskService.drawGrid($scope.addCMModel.task.bandWidth, $scope.addCMModel.task.columns, $scope.addCMModel.task.combineGrid, $scope.addCMModel.task.alongBands);
                    }
                };

                $scope.addCMModel.titleChanged = function() {
                    $scope.addCMModel.isTitleEdited = true;
                };

                $scope.addCMModel.sprayTrackChange = function() {
                    _mapAddTaskService.clearGrid();
                    $scope.addCMModel.grid = null;
                    if ($scope.addCMModel.sprayTrack.id != -1) {
                        $scope.isLoading = true;
                        _apiService.track($scope.addCMModel.accountId, $scope.addCMModel.sprayTrack.id, $scope.addCMModel.task.dividers, $scope.addCMModel.task.selectedTrackOnly ? false : true).then(function (response) {
                            var gridData = {grid : ($scope.addCMModel.task.selectedTrackOnly ? response.data.grid : response.data.averagedGrid) };
                            gridData.track = response.data.track;
                            var g = _mapAddTaskService.showTrackAndGrid(gridData, $scope.addCMModel.fieldId, _view.getCurrentUser().units,
                                $scope.addCMModel.task.combineGrid, $scope.addCMModel.task.alongBands);
                            if (_.isUndefined(gridData.grid)) {
                                Notification.error(gettextCatalog.getString("Can't create grid for this track"));
                            } else {
                                $scope.addCMModel.grid = g;
                                $scope.addCMModel.allowedActivities = _.clone($scope.addCMModel.allowedActivities); //to refresh frtTaskActivities!!!

                                $scope.addCMModel.task.titleData.pointsAmount = $scope.addCMModel.grid.info.pointsNum + 'P';
                                setCropMonitorTaskTitleIfItHasNotBeenModified();
                            }
                            $scope.isLoading = false;
                        }, () => {
                            $scope.isLoading = false;
                            Notification.error(_i18n.getString("common.notifications.apiError"));
                        });
                    }
                };

                $scope.addCMModel.cropMonitorChange = function() {
                    _mapAddTaskService.clearGrid();
                    $scope.addCMModel.grid = null;
                    if ($scope.addCMModel.cropMonitor.id != -1) {
                        $scope.isLoading = true;

                        _apiService.getTaskDetails(_view.getCurrentAccount(), $scope.addCMModel.cropMonitor.id).then( response => {
                            $scope.addCMModel.grid = _mapAddTaskService.showTrackAndGrid(response.data, _view.getCurrentField().id, _view.getCurrentUser().units,
                                $scope.addCMModel.task.combineGrid, $scope.addCMModel.task.alongBands);

                            let pointsAmountFormat = $scope.addCMModel.grid.info.pointsNum + 'P';
                            $scope.addCMModel.task.titleData.pointsAmount = pointsAmountFormat;
                            setCropMonitorTaskTitleIfItHasNotBeenModified();

                            $scope.addCMModel.allowedActivities = _.clone($scope.addCMModel.allowedActivities); //to refresh frtTaskActivities!!!
                            $scope.isLoading = false;
                        }, () => {
                            $scope.isLoading = false;
                            Notification.error(_i18n.getString("common.notifications.apiError"));
                        });
                    }
                };

                $scope.addCMModel.combineGridChange = function() {
                    if ($scope.addCMModel.task.gridType == 'new') {
                        _mapAddTaskService.drawGrid($scope.addCMModel.task.bandWidth, $scope.addCMModel.task.columns, $scope.addCMModel.task.combineGrid, $scope.addCMModel.task.alongBands);
                    } else if ($scope.addCMModel.task.gridType == 'spray') {
                        $scope.addCMModel.sprayTrackChange();
                    }  else if ($scope.addCMModel.task.gridType == 'cm') {
                        $scope.addCMModel.cropMonitorChange();
                    }
                };

                $scope.addCMModel.withinTimeInterval = function (crops, published) {
                    $scope.addCMModel.theStage = null;

                    $scope.addCMModel.sprayTracks = [{id: -1, name: gettextCatalog.getString('Select track')}];
                    $scope.addCMModel.sprayTrack = $scope.addCMModel.sprayTracks[0];

                    $scope.addCMModel.cropMonitors = [{id: -1, name: gettextCatalog.getString('Select crop monitor')}];
                    $scope.addCMModel.cropMonitor = $scope.addCMModel.cropMonitors[0];

                    $scope.addCMModel.crop = _logicService.findCropNowAndFuture(published, published, crops);
                    $scope.addCMModel.futureCrop = $scope.addCMModel.crop && published.isBefore(moment.utc($scope.addCMModel.crop.seedingDate));

                    if ($scope.addCMModel.crop) {
                        var harvesting = _logicService.cropTo($scope.addCMModel.crop);
                        $scope.addCMModel.timeIs.notInInterval = false;
                        $scope.addCMModel.timeIs.inPast = moment.utc().startOf('Day').isAfter(harvesting);
                        $scope.addCMModel.timeIs.inPresentOrFuture = true;

                        if ($scope.addCMModel.crop.variety.crop.name.indexOf("Soybeans") > -1) {
                            $scope.addCMModel.task.standActivitiesPhotosNumber = 5;
                        }

                        _apiService.cropTracks($scope.addCMModel.accountId, $scope.addCMModel.crop.id).then(function(response) {
                            var tracks = _.map(response.data, function(obj) {
                                return {id: obj.id, name: 'track_' + obj.id + " " + _formatService.formatDateAndTime(obj.dateEnd)};
                            });
                            tracks = _.sortBy(tracks, 'id').reverse();
                            $scope.addCMModel.sprayTracks = $scope.addCMModel.sprayTracks.concat(tracks);
                        }, () => {
                            Notification.error(_i18n.getString("common.notifications.apiError"));
                        });

                        $scope.addCMModel.isNoStages = _.isEmpty($scope.addCMModel.crop.stages);
                        if (!$scope.addCMModel.isNoStages) {
                            $scope.addCMModel.theStage = _.find($scope.addCMModel.crop.stages, function (stage) {
                                var startDate = moment.utc(stage.startDate),
                                    endDate = moment.utc(stage.endDate);
                                return (published.isBetween(startDate, endDate, null, '[]'));
                            });
                        }
                    }

                    if ($scope.addCMModel.crop) { //fill crop monitors only if we are able to create new cm
                        var tasks = [];
                        _.each(crops, function (aCrop) {
                            var harvesting = _logicService.cropTo(aCrop);

                            if (published.isSameOrAfter(harvesting) || $scope.addCMModel.crop.id === aCrop.id) { //past or present crop
                                _.each(aCrop.tasks, function(task) {
                                    if (task.type == 'cropmonitor' && moment.utc(task.from).isBefore(published)) {
                                        tasks.push({id: task.id, from: moment.utc(task.from), name: task.label});
                                    }
                                });
                            }
                        });
                        _.sortBy(tasks, 'from');
                        tasks.reverse();
                        $scope.addCMModel.cropMonitors = $scope.addCMModel.cropMonitors.concat(tasks);
                    }
                };

                var datePub = $scope.addCMModel.task.datePublished.date;
                Object.defineProperty($scope.addCMModel.task.datePublished, 'date', {
                    get: function() {
                        return datePub;
                    },
                    set: function(newValue) {
                        $scope.isLoading = true;
                        datePub = newValue;

                        $scope.addCMModel.timeIs = {notInInterval: true};

                        if (_.isNull(datePub) || _.isUndefined(datePub)) {
                            //do nothing
                            $scope.isLoading = false;
                        } else {
                            _apiService.fieldCrops($scope.addCMModel.accountId, $scope.addCMModel.fieldId, true, true).then(response => {
                                if (_.size(response.data) > 0) {
                                    $scope.addCMModel.withinTimeInterval(response.data, _logicService.javascriptDateToMoment(datePub).startOf('Day'));
                                }
                                $scope.isLoading = false;
                            }, () => {
                                $scope.isLoading = false;
                                Notification.error(_i18n.getString("common.notifications.apiError"));
                            });

                            loadAllowedActivityTypes($scope.addCMModel.accountId, $scope.addCMModel.fieldId, _logicService.javascriptDateToMoment(datePub).startOf('Day')).then(function (allowed) {
                                $scope.addCMModel.allowedActivities = {
                                    STAND: 0,
                                    DISEASES: 0,
                                    INSECTS: 0,
                                    FENOLOGY: 0,
                                    WEEDS: 0,
                                    NOTE: 1
                                };
                                _.each(allowed, function (value, key, list) {
                                    $scope.addCMModel.allowedActivities[key] = value;
                                });
                            });
                        }
                    }
                });

                var taskDate = _flashService.get('taskDate');
                if (taskDate) {
                    $scope.addCMModel.task.datePublished.date = _logicService.momentUtcToDatepickerDate(taskDate);
                } else {
                    $scope.addCMModel.task.datePublished.date = _logicService.momentUtcToDatepickerDate(moment.utc().startOf('day'));
                }

                $scope.addCMModel.task.datePublished.initDate = $scope.addCMModel.task.datePublished.date;

                /**
                 * Apply to task title following filter format: <'CM-'> + <date.format('MM/DD/YYYY')-> + <points-amount> + <P>
                 * For example: CM-06/27/2017-85P
                 * @type {String}
                 */
                let titleDateFormat = _logicService.javascriptDateToMoment($scope.addCMModel.task.datePublished.date).format('MM/DD/YYYY');
                $scope.addCMModel.task.titleData.date = titleDateFormat ;

                setCropMonitorTaskTitleIfItHasNotBeenModified();

                $scope.isLoading = false;
            }
        }

        function clearAddCropMonitorWhenTasksWereSwitched(tasks) {
            if (tasks.current !== tasks.prev) {
                clearAddCropMonitor();
            }

            initAddCropMonitor();
        }

        var loadAllowedActivityTypes = function(accountId, fieldId, date) {
            var deferred = $q.defer();

            _apiService.fieldSowingsOnDate(accountId, fieldId, date.toISOString()).then(function(response) {
                    var sowings = response.data;

                    if (_.size(sowings) > 0) {
                        _apiService.getActivitiesDistributionOnDate(accountId, sowings[0].id, date.format('YYYY-MM-DD')).then(function(activities) {
                                deferred.resolve(activities);
                            },
                            () => {
                                Notification.error(_i18n.getString("common.notifications.apiError"));
                            });
                    } else {
                        deferred.resolve({});
                    }
                },
                () => {
                    Notification.error(_i18n.getString("common.notifications.apiError"));
                });

            return deferred.promise;
        };

        function clearViewCropMonitorWhenTasksWereSwitched(tasks) {
            if (tasks.current !== tasks.prev) {
                $scope.scaleY = GRAPH_X1;
                clearViewCropMonitor();
            }

            initViewCropMonitor();
        }

        function initViewCropMonitor() {
            if (_view.is.viewCropMonitor()) {
                $scope.isLoading = true;
                $scope.report = false;
                $scope.inputModel = {showScoutMovement: false};
                $scope.scoutPoint = null;
                $scope.dangerMapNotReady = false;
                $scope.dangerMapNotExists = false;
                $scope.dangerMapOutOfDates = false;
                $scope.insectWarnings = [];
                $scope.isThresholdCustom = {
                    insects: null,
                    diseases: null,
                    weeds: null
                };

                var currentField = (_view.isFieldSelected() || _view.isFarmAndFieldSelected()) ? _view.getCurrentField() : _view.getPanelField();
                var fieldId = currentField.id;

                $q.all({
                    taskResponse: _apiService.getTaskDetails(_view.getCurrentAccount(), _view.getViewCropMonitor()),
                    cropsResponse: _apiService.fieldCrops(_view.getCurrentAccount(), fieldId, true),
                    diseasesResponse: _apiService.getDiseases(_view.getCurrentAccount()),
                    insectsResponse: _apiService.getInsects(_view.getCurrentAccount()),
                    trackResponse: _apiService.getTaskTrack(_view.getCurrentAccount(), _view.getViewCropMonitor()),
                    taskInsectsResponse: _apiService.getTaskInsects(_view.getCurrentAccount(), _view.getViewCropMonitor())
                }).then(function (values) {
                    $scope.isLoading = false;
                    document.querySelector('body').onkeydown = function (e) {
                        if (e.keyCode == 27) {
                            document.querySelector('body').onkeydown = null;
                            $rootScope.$safeApply(function() {
                                $scope.closeViewCropMonitor();
                            });
                        }
                    };

                    $scope.insectsDict = values.insectsResponse.data;
                    $scope.diseasesDict = values.diseasesResponse.data;

                    $scope.task = values.taskResponse.data;
                    $scope.task.insects = _taskService.buildTaskInsects($scope.insectsDict, values.taskInsectsResponse.data);
                    $scope.task.insectsHasNotes = _taskService.insectsHasNotes($scope.task.insects);
                    offPADisease();
                    offRegularCommonDangerMap();
                    offRegularInsectDangerMap();

                    $scope.task.hasPA = _.size($scope.task.paInsectSamplings) > 0 || _.size($scope.task.paDiseaseSamplings) > 0;
                    $scope.task.hasRegular = false;

                    $scope.task.points.forEach(scoutPoint => {
                        $scope.task.hasRegular = $scope.task.hasRegular || _.size(scoutPoint.requiredActivities) > 0 || _.size(scoutPoint.optionalActivities) > 0
                    });

                    var dateStart = moment.utc($scope.task.datePublished);
                    var dateFinish = moment.utc($scope.task.dateDue);
                    buildDates(dateStart, dateFinish);

                    $scope.scoutPoints = null;
                    $scope.task.scoutPointsLength = 0;

                    if (!_.isEmpty(values.trackResponse.data)) { //we have track
                        var reader = new jsts.io.GeoJSONReader();

                        var minTs = null;
                        var maxTs = null;
                        $scope.scoutPoints = _.map(values.trackResponse.data.features, function (feature) {
                            if (feature.properties.timestamp > 0) {
                                if (minTs == null) {
                                    minTs = feature.properties.timestamp;
                                    maxTs = feature.properties.timestamp;
                                } else {
                                    if (feature.properties.timestamp < minTs) {
                                        minTs = feature.properties.timestamp;
                                    }
                                    if (feature.properties.timestamp > maxTs) {
                                        maxTs = feature.properties.timestamp;
                                    }
                                }
                            }
                            return reader.read(feature.geometry);
                        });

                        $scope.task.scoutPointsLength = _logicService.round(_mapAddTaskService.trackLength($scope.scoutPoints), 0);

                        if (minTs != null && maxTs != null) {
                            buildDates(moment.utc(minTs), moment.utc(maxTs), currentField.timezoneId);
                        }
                    }

                    $scope.task.hasResults = false;
                    $scope.task.donePointsCount = 0;
                    $scope.task.partialPointsCount = 0;
                    $scope.task.points.forEach(scoutPoint => {
                        $scope.task.hasResults = $scope.task.hasResults || _.size(scoutPoint.results) > 0;
                        if (scoutPoint.status == "DONE") {
                            $scope.task.donePointsCount++;
                        }
                        if (scoutPoint.status == "PARTIAL") {
                            $scope.task.partialPointsCount++;
                        }
                    });

                    $scope.crops = values.cropsResponse.data;
                    $scope.crop = _.find($scope.crops, function (c) { return c.id == $scope.task.sowing.id;});

                    if ($scope.task.hasPA) { //load insect and disease names
                        $scope.task.paDiseaseSamplingsGroup = _taskService.buildPaDiseaseSamplingsGroup($scope.task, $scope.diseasesDict);
                        $scope.task.paInsectSamplingsGroup = _taskService.buildPAInsectSamplingsGroup($scope.task, $scope.insectsDict);
                    }
                    if ($scope.task.hasRegular) {
                        $scope.task.activitiesStat = _taskService.buildActivitiesStat($scope.task);
                        $scope.task.sortedRegularActivities = _logicService.getSortedRegularActivities();
                    }

                    console.log("$scope.task", $scope.task);

                    $scope.task.status_label = _i18n.getString("task.status." + $scope.task.status);

                    if (_view.is.map()) {
                        $scope.task.areaPerPoint = $scope.task.points.length > 0 ? (_mapAddTaskService.fieldArea(fieldId) / $scope.task.points.length) : 0;

                        // grid
                        _mapFieldService.panToFields([{id: fieldId}]);
                        _mapFieldService.hideAllFieldsExceptOne(fieldId);
                        _mapAddTaskService.drawTaskGrid($scope.task);

                        // scoutResults
                        _taskService.groupResults($scope.task);

                        _mapAddTaskService.drawTaskResults($scope.task);

                        if ($scope.task.hasPA) {
                            showPADiseases();
                        }

                        if (_view.getScoutPoint()) { //select scout point
                            _mapAddTaskService.scoutPointClick(_view.getScoutPoint().pointIndex);
                        }
                    } else if (_view.is.weather()) {
                        $scope.task.insect = null;
                        if (_view.getScoutPoint()) {
                            $scope.isLoading = true;
                            _apiService.getCellInsects(_view.getCurrentAccount(), _view.getViewCropMonitor(), _view.getScoutPoint().cellIndex).then(
                                function (response) {
                                    $scope.task.insects = _taskService.buildTaskInsects($scope.insectsDict, response.data);
                                    $scope.isLoading = false;
                                }, function () {
                                    $scope.isLoading = false;
                                    Notification.error(_i18n.getString("common.notifications.apiError"));
                                }
                            )
                        }
                    }
                }, function () {
                    $scope.isLoading = false;
                    Notification.error(_i18n.getString("common.notifications.apiError"));
                });
            } else {
                clearViewCropMonitor();
            }
        }

        function buildDates(dateStart, dateFinish, timezoneId) {
            $scope.task.datePublishedStr = _formatService.formatDateAndTime(dateStart, timezoneId);
            $scope.task.dateDueStr = _formatService.formatDateAndTime(dateFinish, timezoneId);
            var totalMinutes = dateFinish.diff(dateStart, 'minutes');
            $scope.task.durationDays = Math.floor(totalMinutes / (60 * 24)); //days
            $scope.task.durationHours = Math.floor(totalMinutes / (60)) % 24; //hours
            $scope.task.durationMinutes = totalMinutes % 60; //min
        }

        $scope.selectInsectNote = function(insectId) {
            offPADisease();
            offRegularCommonDangerMap();

            var insect = _.find($scope.task.insects, function (e) { return e.id == insectId});
            if (insect.selected) {
                if (insect.noteOn) {
                    insect.noteOn = false;
                } else {
                    insect.noteOn = true;
                }
            } else {
                insect.selected = true;
                insect.noteOn = true;
            }

            showRegularDangerMap();
        };

        function offPADisease() {
            _mapAddTaskService.resetPADangerMap(); //clear PA danger map
            $scope.selectedPADiseaseId = null;
            $scope.scale = null;
        }

        function offRegularCommonDangerMap() {
            _mapAddTaskService.clearPointsHighlight();
            $scope.regularDangerMap = false;
            $scope.regularDangerMapNote = false;
            $scope.insectWarnings = [];
        }

        function offRegularInsectDangerMap() {
            _mapAddTaskService.clearPointsHighlight();
            _.each($scope.task.insects, function (e) { e.selected = false; e.noteOn = false; });
            $scope.insectWarnings = [];
        }

        $scope.selectInsectDangerMap = function(insectId) {
            offPADisease();
            offRegularCommonDangerMap();

            var insect = _.find($scope.task.insects, function (e) { return e.id == insectId});
            if (insect.selected) {
                insect.selected = false;
                insect.noteOn = false;
            } else {
                insect.selected = true;
            }

            showRegularDangerMap();
        };

        $scope.selectRegularDangerMapNote = function () {
            offPADisease();
            offRegularInsectDangerMap();

            if (!$scope.regularDangerMap) {
                $scope.regularDangerMap = true;
                $scope.regularDangerMapNote = true;
            } else {
                $scope.regularDangerMapNote = !$scope.regularDangerMapNote;
            }

            showRegularDangerMap();
        };

        $scope.selectRegularDangerMap = function () {
            offPADisease();
            offRegularInsectDangerMap();

            if (!$scope.regularDangerMap) {
                $scope.regularDangerMap = true;
            } else {
                $scope.regularDangerMap = false;
                $scope.regularDangerMapNote = false;
            }

            showRegularDangerMap();
        };

        $scope.selectPADisease = function(id) {
            offRegularCommonDangerMap();
            offRegularInsectDangerMap();
            showRegularDangerMap(); //to clear all regular DM warnings

            _mapAddTaskService.resetPADangerMap(); //clear PA danger map

            if ($scope.selectedPADiseaseId == id) {
                offPADisease();
            } else {
                $scope.selectedPADiseaseId = id;
                var diseaseModel = _.find($scope.paDiseaseModels, function (e) {
                    return e.diseaseId == id;
                });

                var matrix = [];
                for (var i = 0; i < $scope.task.grid.ny; i++) {
                    var row = [];
                    for (var j = 0; j < $scope.task.grid.nx; j++) {
                        row.push(null);
                    }
                    matrix.push(row);
                }

                $scope.task.points.forEach(scoutPoint => {
                    scoutPoint.results.forEach(result => {
                        if (result.type == "PA_DISEASE") {
                            var json = angular.fromJson(result.resultJson);
                            if (json.disease.id == diseaseModel.diseaseId) {
                                _.each(scoutPoint.affectedCells, function (a) {
                                    if (_.isUndefined(json.selectedEvaluationScaleItem) || _.isNull(json.selectedEvaluationScaleItem)) {
                                        matrix[a[0]][a[1]] = "#00FF00";
                                    } else {
                                        matrix[a[0]][a[1]] = json.selectedEvaluationScaleItem.color;
                                    }
                                });
                            }
                        }
                    });
                });
                $scope.scale = diseaseModel.scale;
                _mapAddTaskService.drawPADangerMap(matrix);
            }
        };

        $scope.getReport = function () {
            var form = $("#reportForm");
            form.attr('action', _apiService.taskReportUrl($scope.task.id));
            form.append("<input type='hidden' name='accountId' value='"+_view.getCurrentAccount()+"'/>");
            form.append("<input type='hidden' name='access_token' value='"+_appStorage.get('token')+"'/>");
            form.submit();
            form.find("input").remove();
        };

        $scope.getReportXlsx = function () {
            var form = $("#reportXlsxForm");
            form.attr('action', _apiService.taskXlsxReportUrl($scope.task.id));
            form.append("<input type='hidden' name='accountId' value='"+_view.getCurrentAccount()+"'/>");
            form.append("<input type='hidden' name='access_token' value='"+_appStorage.get('token')+"'/>");
            form.submit();
            form.find("input").remove();
        };

        function showPADiseases() {
            $scope.paDiseases = [];
            $scope.paDiseaseModels = [];
            $scope.selectedPADiseaseId = null;
            $scope.scale = null;

            _apiService.diseaseAllScales(_view.getCurrentAccount()).then(function(res) {
                var scales = [];
                _.each(res.data, function (e) {
                    _.each(e.scales, function (s) {
                        s.formName = _i18n.getStringFromBundleEn(s.name);
                        _.each(s.items, function (i) { i.formName = _i18n.getStringFromBundleEn(i.name); });
                        scales.push(s);
                    });
                });

                $scope.task.points.forEach(scoutPoint => {
                    scoutPoint.results.forEach(result => {
                        if (result.type == "PA_DISEASE") {
                            var json = angular.fromJson(result.resultJson);
                            var diseaseModel = _.find($scope.paDiseaseModels, function (e) {
                                return e.diseaseId == json.disease.id;
                            });
                            if (!diseaseModel) {
                                $scope.paDiseases.push({id: json.disease.id, formName: _i18n.getStringFromBundleLat(json.disease.name)});
                                diseaseModel = {diseaseId: json.disease.id};
                                var scale = _.find(scales, function (e) { return e.id == json.samplingObject.evaluationScaleId; });
                                if (scale) {
                                    diseaseModel.scale = _.clone(scale);
                                }
                                $scope.paDiseaseModels.push(diseaseModel);
                            }
                        }
                    });
                });
            },
            () => {
                Notification.error(_i18n.getString("common.notifications.apiError"));
            });
            $scope.isLoading = false;
        }

        function showRegularDangerMap() {
            _mapAddTaskService.resetRegularDangerMap();
            _mapAddTaskService.clearPointsHighlight();
            $scope.dangerMapNotReady = false;
            $scope.dangerMapNotExists = false;
            $scope.dangerMapOutOfDates = false;
            $scope.isThresholdCustom.insects = false;
            $scope.insectWarnings = [];

            var insectsIds = [ ];
            var withNotesInsectsIds = [ ];

            if ($scope.regularDangerMap) {
                insectsIds = _.pluck(_.filter($scope.task.insects, function (e) { return $scope.regularDangerMapNote || e.task == true; }), 'id');
                if ($scope.regularDangerMapNote) {
                    withNotesInsectsIds = _.pluck(_.filter($scope.task.insects, function (e) { return (e.task == true && e.note == true) || (e.task == false && e.note == true); }), 'id');
                }
            } else {
                _.each($scope.task.insects, function (e) {
                    if (e.selected) {
                        insectsIds.push(e.id);
                        if (e.task == false) { //pure photonote insect
                            withNotesInsectsIds.push(e.id);
                        } else { //task insect
                            if (e.noteOn) {
                                withNotesInsectsIds.push(e.id);
                            }
                        }
                    }
                });
            }

            if (_.size(insectsIds) == 0 || ($scope.task.status != "DONE" && $scope.task.status != "ABANDONED")) {
                $scope.isLoading = false;
                return;
            }

            $scope.isLoading = true;

            var matrix = [];
            for (var i = 0; i < $scope.task.grid.ny; i++) {
                var row = [];
                for (var j = 0; j < $scope.task.grid.nx; j++) {
                    row.push("");
                }
                matrix.push(row);
            }

            _calendar.itemsPromise.then(function(items) {
                var cropMonitorCycle = _logicService.cropMonitorCycle($scope.task, $scope.crop);
                var date = _.find(items, function(i) { return i.current; }).date;

                if (date.isBetween(cropMonitorCycle.from, cropMonitorCycle.to, null, "[]")) {
                    $scope.dangerMapOutOfDates = false;

                    $q.all({
                        dangerResponse: _apiService.getDangerMap(_view.getCurrentAccount(), {id: $scope.task.sowing.id}, date.format("YYYY-MM-DD"), null, insectsIds, withNotesInsectsIds),
                        flagsResponse: _apiService.insectThresholdsFlags(_view.getCurrentAccount(), $scope.crop.variety.crop.id)
                    }).then(function (values) {
                            $scope.dangerMapNotReady = values.dangerResponse.status == 202;
                            $scope.dangerMapNotExists = values.dangerResponse.status == 204;
                            $scope.dangerMap = values.dangerResponse.data;

                            if (!$scope.dangerMapNotReady && !$scope.dangerMapNotExists) {
                                _.each($scope.dangerMap.warning, function (warningItem) {
                                    matrix[warningItem[0]][warningItem[1]] = "warning";
                                });
                                _.each($scope.dangerMap.danger, function (dangerItem) {
                                    matrix[dangerItem[0]][dangerItem[1]] = "danger";
                                });
                            }

                            $scope.isThresholdCustom.insects = _.has(values.dangerResponse.data, 'customized') && values.dangerResponse.data.customized;

                            _mapAddTaskService.drawDangerMap(matrix);

                            var warningInsectsIds = [];
                            _.each(insectsIds, function (insectId) {
                                if (values.flagsResponse.data[insectId].allNA && values.flagsResponse.data[insectId].hasDanger) {
                                    warningInsectsIds.push(insectId);
                                    $scope.insectWarnings.push({
                                        insectId: insectId,
                                        text: gettextCatalog.getString("{{name}} has no threshold preset.", {
                                            name: _i18n.getStringFromBundleLat(_.find($scope.insectsDict, function (e) { return e.id == insectId;}).name)
                                        })
                                    });
                                }
                            });

                            _mapAddTaskService.highlightPoints(_taskService.getPointsWithInsects($scope.task, warningInsectsIds));

                            $scope.isLoading = false;
                        },
                        () => {
                            $scope.isLoading = false;
                            Notification.error(_i18n.getString("common.notifications.apiError"));
                        }
                    );
                } else {
                    $scope.dangerMapOutOfDates = true;
                    $scope.isLoading = false;
                    _mapAddTaskService.drawDangerMap(matrix); //empty danger map will clear points
                }
            });
        }

        $scope.editThreshold = function(type, insectId) {
            _view.setViewThresholds({id: $scope.crop.id, type: type, crop: $scope.crop, insectId: insectId});
            $rootScope.$broadcast('aside:opened');
        };

        $scope.showScoutMovementChange = function() {
            $scope.isLoading = true;

            if ($scope.inputModel.showScoutMovement) {
                if ($scope.scoutPoints != null) {
                    _mapAddTaskService.drawScoutTrack($scope.scoutPoints);
                    $scope.isLoading = false;
                } else {
                    Notification.error(gettextCatalog.getString("Scout movement track not found"));
                    $scope.isLoading = false;
                }
            } else {
                _mapAddTaskService.hideScoutTrack();
                $scope.isLoading = false;
            }
        };

        $scope.scaleY = GRAPH_X1;

        $scope.selectInsectNoteOnWeather = function (insectId) {
            if ($scope.task.insect) {
                if ($scope.task.insect.id == insectId) {
                    if ($scope.task.insect.noteOn) {
                        $scope.task.insect.noteOn = false;
                    } else {
                        $scope.task.insect.noteOn = true;
                    }
                } else {
                    $scope.task.insect.noteOn = false;
                    $scope.task.insect = _.find($scope.task.insects, function (i) { return i.id == insectId; });
                    $scope.task.insect.noteOn = true;
                }
            } else {
                $scope.task.insect = _.find($scope.task.insects, function (i) { return i.id == insectId; });
                $scope.task.insect.noteOn = true;
            }

            insectGraph();
        };

        $scope.selectInsectOnWeather = (insectId) => {

            if ($scope.task.insect) {
                if ($scope.task.insect.id == insectId) {
                    $scope.task.insect.noteOn = false;
                    $scope.task.insect = null;
                } else {
                    $scope.task.insect.noteOn = false;
                    $scope.task.insect = _.find($scope.task.insects, function (i) { return i.id == insectId; });
                }
            } else {
                $scope.task.insect = _.find($scope.task.insects, function (i) { return i.id == insectId; });
            }

            insectGraph();
        };

        function insectGraph() {
            _chartService.clearSprayGraph();

            if (_.isNull($scope.task.insect) || _.isUndefined($scope.task.insect)) {
                return;
            }

            $scope.isLoading = true;
            _apiService.thresholdUnit(_view.getCurrentAccount(), $scope.task.id, $scope.task.insect.id).then(
                unitResponse => {
                    $scope.thresholdUnit = unitResponse.data.thresholdUnit;
                    var noteOn = ($scope.task.insect.task == false) || ($scope.task.insect.noteOn == true);

                    var requests = {
                        thresholdsResponse:  _apiService.sowingThresholds(_view.getCurrentAccount(), $scope.task.sowing.id, $scope.task.insect.id, $scope.thresholdUnit),
                        insectResponse: _apiService.sowingInsects(_view.getCurrentAccount(), $scope.task.sowing.id, $scope.task.insect.id, noteOn)
                    };
                    if (_view.getScoutPoint()) {
                        requests.cellInsectResponse = _apiService.getTaskCellInsectsChart(_view.getCurrentAccount(), $scope.task.id, _view.getScoutPoint().cellIndex, $scope.task.insect.id, noteOn)
                    }
                    $q.all(requests).then(function (values) {
                        $scope.thresholds = values.thresholdsResponse.data;
                        $scope.insectsGraphDataNotReady = values.insectResponse.status == 202;
                        $scope.insectsGraphDataNotExists = values.insectResponse.status == 204;
                        $scope.insectsGraphData = values.insectResponse.data;

                        if (!$scope.insectsGraphDataNotReady && !$scope.insectsGraphDataNotExists) {
                            if (_view.getScoutPoint()) {
                                $scope.scoutPointData = values.cellInsectResponse.data;
                                _chartService.buildSprayGraphModel($scope.scaleY, $scope.thresholds, $scope.thresholdUnit, $scope.insectsGraphData, $scope.task.insect, $scope.scoutPointData, _view.getScoutPoint().pointIndex);

                                $scope.zoomIn = function () {
                                    $scope.scaleY *= GRAPH_HALF;
                                    _chartService.buildSprayGraphModel($scope.scaleY, $scope.thresholds, $scope.thresholdUnit, $scope.insectsGraphData, $scope.task.insect, $scope.scoutPointData, _view.getScoutPoint().pointIndex);
                                };

                                $scope.zoomOut = function () {
                                    $scope.scaleY *= GRAPH_X2;
                                    _chartService.buildSprayGraphModel($scope.scaleY, $scope.thresholds, $scope.thresholdUnit, $scope.insectsGraphData, $scope.task.insect, $scope.scoutPointData, _view.getScoutPoint().pointIndex);
                                };
                            } else {
                                _chartService.buildSprayGraphModel($scope.scaleY, $scope.thresholds, $scope.thresholdUnit, $scope.insectsGraphData, $scope.task.insect);

                                $scope.zoomIn = function () {
                                    $scope.scaleY *= GRAPH_HALF;
                                    _chartService.buildSprayGraphModel($scope.scaleY, $scope.thresholds, $scope.thresholdUnit, $scope.insectsGraphData, $scope.task.insect);
                                };

                                $scope.zoomOut = function () {
                                    $scope.scaleY *= GRAPH_X2;
                                    _chartService.buildSprayGraphModel($scope.scaleY, $scope.thresholds, $scope.thresholdUnit, $scope.insectsGraphData, $scope.task.insect);
                                };
                            }
                        }

                        $scope.isLoading = false;
                    }, function () {
                        $scope.isLoading = false;
                        Notification.error(_i18n.getString("common.notifications.apiError"));
                    });
                }, () => { //_apiService.thresholdUnit
                    $scope.isLoading = false;
                    Notification.error(_i18n.getString("common.notifications.apiError"));
                }
            );
        }

        $scope.$on("_calendar:width-changed", function() {
            if (_view.is.viewCropMonitor() && _view.is.weather() && $scope.task && $scope.task.insect && $scope.thresholds && $scope.insectsGraphData) {
                _chartService.buildSprayGraphModel($scope.scaleY, $scope.thresholds, $scope.thresholdUnit, $scope.insectsGraphData, $scope.task.insect, $scope.scoutPointData, _view.getScoutPoint() ? _view.getScoutPoint().pointIndex : "");
            }
        });

        $scope.$on("_calendar:changed", function() {
            if (_view.is.viewCropMonitor() && $scope.task) {
                if (_view.is.weather()) {
                    if ($scope.task.insect && $scope.thresholds &&  $scope.insectsGraphData) {
                        _chartService.buildSprayGraphModel($scope.scaleY, $scope.thresholds, $scope.thresholdUnit, $scope.insectsGraphData, $scope.task.insect, $scope.scoutPointData, _view.getScoutPoint() ? _view.getScoutPoint().pointIndex : "");
                    }
                } else if (_view.is.map() && $scope.crop) {
                    showRegularDangerMap();
                }
            }
        });

        $scope.$on('mapService:gridCompleted', function(event, data) {
            $scope.addCMModel.grid = data;
            $scope.addCMModel.allowedActivities = _.clone($scope.addCMModel.allowedActivities); //to refresh frtTaskActivities!!!

            let pointsAmountFormat = data.info.pointsNum + 'P';
            $scope.addCMModel.task.titleData.pointsAmount = pointsAmountFormat;
            setCropMonitorTaskTitleIfItHasNotBeenModified();
        });

        $scope.$on('mapService:manualPointAdded', function(event) {

            $scope.addCMModel.allowedActivities = _.clone($scope.addCMModel.allowedActivities); //to refresh frtTaskActivities!!!

            let pointsAmountFormat = _.size($scope.addCMModel.manualPoints) + 'P';
            $scope.addCMModel.task.titleData.pointsAmount = pointsAmountFormat;
            setCropMonitorTaskTitleIfItHasNotBeenModified();
        });

        $scope.$on('mapService:scoutPointClick', function(event, scoutPoint) {
            $scope.isLoading = true;
            $scope.scoutPoint = scoutPoint;
            _view.setScoutPoint(scoutPoint);

            $q.all({
                taskInsectsResponse: $scope.scoutPoint ? _apiService.getCellInsects(_view.getCurrentAccount(), _view.getViewCropMonitor(), $scope.scoutPoint.cellIndex)
                    : _apiService.getTaskInsects(_view.getCurrentAccount(), _view.getViewCropMonitor())
            }).then(function (values) {
                $scope.task.insects = _taskService.buildTaskInsects($scope.insectsDict, values.taskInsectsResponse.data);
                $scope.task.insectsHasNotes = _taskService.insectsHasNotes($scope.task.insects);
                offPADisease();
                offRegularCommonDangerMap();
                offRegularInsectDangerMap();
                _mapAddTaskService.resetPADangerMap(); //clear PA danger map
                showRegularDangerMap(); //clear regular danger map

                $scope.isLoading = false;
            }, function () {
                $scope.isLoading = false;
                Notification.error(_i18n.getString("common.notifications.apiError"));
            });

            _v.change({set: {viewPhotoNote: undefined}});
        });

        function clearViewCropMonitor() {
            _mapFieldService.showAllFields();
            _mapAddTaskService.clearCropMonitor();
            $scope.task = null;
            _chartService.clearSprayGraph();
            $scope.insectWarnings = [];
        }

        function clearAddCropMonitor() {
            _mapAddTaskService.switchMode();
            _mapFieldService.showAllFields();
        }

        function updateCropMonitorTaskTitle() {
            $scope.addCMModel.task.title = $filter('cropMonitorTaskName')($scope.addCMModel.task.titleData);
        }

        function setCropMonitorTaskTitleIfItHasNotBeenModified() {
            if (!$scope.addCMModel.isTitleEdited) {
                updateCropMonitorTaskTitle();
            }
        }

        $scope.openReport = function () {
            $scope.report = ! $scope.report;


        };

        $scope.closeViewCropMonitor = function() {
            clearViewCropMonitor();
            _v.change({set: {viewCropMonitor: undefined}});
        };

        $scope.closeAddCropMonitor = function() {
            clearAddCropMonitor();
            _v.change({set: {addCropMonitor: undefined}});
        };

        /**
         * Save new task
         */
        $scope.saveCropMonitor = function(form) {
            if (!form.$valid) {
                _.each(form.$error.required, function (e) { e.$setDirty(); });
                angular.element('input.ng-invalid').first().focus();
                return;
            }

            $scope.isLoading = true;

            var dateStart = _logicService.javascriptDateToMoment($scope.addCMModel.task.datePublished.date).startOf('day');
            var dateFinish = dateStart.clone().endOf("day");

            var assignment = {
                title: $scope.addCMModel.task.title,
                description: "description",
                points: $scope.addCMModel.task.gridType == 'none' ? _mapAddTaskService.getScoutPointsAsGeoJSONFromManualPoins() : _mapAddTaskService.getScoutPointsAsGeoJSON(),
                datePublished: dateStart.format(_logicService.DATETIME_FORMAT),
                dateDue: dateFinish.format(_logicService.DATETIME_FORMAT),
                grid: $scope.addCMModel.task.gridType == 'none' ? _mapAddTaskService.getGridFromManualPoints() : _mapAddTaskService.getGrid(),
                gridGroupSize: $scope.addCMModel.task.combineGrid,
                sowingId: $scope.addCMModel.crop ? $scope.addCMModel.crop.id : undefined,
                standActivitiesPhotosNumber: $scope.addCMModel.task.standActivitiesPhotosNumber,
                standRowLength: $scope.addCMModel.task.standRowLength
            };

            //task has sowing check
            if (_.isUndefined(assignment.sowingId)) {
                Notification.error(_i18n.getString("task.notifications.saveAssignment.errorNoSowing"));
                $scope.isLoading = false;
                return;
            }

            //task has correct grid check
            if (_.isNull(assignment.grid)) {
                Notification.error(gettextCatalog.getString("Wrong grid"));
                $scope.isLoading = false;
                return;
            }

            //task has points check
            if (_.isEmpty(assignment.points.features)) {
                Notification.error(gettextCatalog.getString("Crop monitor must have scout points"));
                $scope.isLoading = false;
                return;
            }

            //task date is free check
            if (!_logicService.checkFreeDate($scope.addCMModel.crop, dateStart, dateFinish)) {
                $scope.isLoading = false;
                Notification.error(gettextCatalog.getString("Task dates are occupied by other task."));
                return;
            }

            //task do not break sparay cycle check
            if (!_logicService.checkSprayCycle($scope.addCMModel.crop, dateStart, dateFinish)) {
                $scope.isLoading = false;
                Notification.error(gettextCatalog.getString("New crop monitor task can not be before spray."));
                return;
            }

            var hasOnSowingOnlyActivity = false;

            //regular logic /////////////////////////////////////////////////////////////////////
            var allPointsHasActivity = true;
            var hasRegularDiseases = false;
            var hasRegularInsects = false;

            var optionalActivities = _.collect(_.pairs($scope.addCMModel.freeActivities).filter(function (keyVal) {
                return keyVal[1];
            }), function (keyVal) {
                return keyVal[0]
            });

            assignment.points.features.forEach(point => {

                point.properties.skipPhotoActivities = [];
                _.each($scope.addCMModel.skipPhotoActivities, function (value, key) {
                    if (value) {
                        point.properties.skipPhotoActivities.push(key);
                    }
                });

                if (_.size(_.intersection(point.properties.requiredActivities, _logicService.getOnSowingActivities())) > 0) {
                    hasOnSowingOnlyActivity = true;
                }
                if (_.size(_.intersection(point.properties.optionalActivities, _logicService.getOnSowingActivities())) > 0) {
                    hasOnSowingOnlyActivity = true;
                }

                point.properties.optionalActivities = _.filter(optionalActivities, function (e) {
                    return !_.contains(point.properties.requiredActivities, e)
                });
                if (_.size(point.properties.optionalActivities) == 0) {
                    if (_.isEmpty(point.properties.requiredActivities)) {
                        allPointsHasActivity = false;
                    }
                }
                hasRegularInsects = hasRegularInsects || _.contains(point.properties.requiredActivities, "INSECTS");
                hasRegularInsects = hasRegularInsects || _.contains(point.properties.optionalActivities, "INSECTS");

                hasRegularDiseases = hasRegularDiseases || _.contains(point.properties.requiredActivities, "DISEASES");
                hasRegularDiseases = hasRegularDiseases || _.contains(point.properties.optionalActivities, "DISEASES");
            });

            //pa logic /////////////////////////////////////////////////////////////////////

            var allScalesAreUtmost = true;
            var allPlantsNumberZero = true;
            assignment.plantsNumber = $scope.addCMModel.task.plantsNumber;
            assignment.paInsectSamplings = _.map($scope.PAInsects, function (e) {
                if (e.scale.items.length > 1) {
                    allScalesAreUtmost = false;
                }
                if (e.number > 0) {
                    allPlantsNumberZero = false;
                }
                return {insectId: e.insect.id, plantsNumber: e.number, scaleId: e.scale.id, stages: _.map(e.stages, function (s){ return s.id})};
            });
            var hasPAInsects = _.size(assignment.paInsectSamplings) > 0;

            assignment.paDiseaseSamplings = [];
            _.each($scope.PADiseases, function (e) {
                if (e.disease && e.scale) {
                    assignment.paDiseaseSamplings.push({diseaseId: e.disease.id, plantsNumber: e.number, scaleId: e.scale.id});
                    hasOnSowingOnlyActivity = true;
                }
            });
            var hasPADiseases = _.size(assignment.paDiseaseSamplings) > 0;

            //unique insect and disease check
            var allUniqueInsect = true;
            _.each(assignment.paInsectSamplings, function (e1, i1) {
                _.each(assignment.paInsectSamplings, function (e2, i2) {
                    if (i1 != i2 ) {
                        if (e1.insectId == e2.insectId) {
                            _.each(e1.stages, function (s) {
                                if (_.contains(e2.stages, s)) {
                                    allUniqueInsect = false;
                                }
                            });
                            if (allUniqueInsect) {
                                if (e1.scaleId == e2.scaleId && e1.plantsNumber == e2.plantsNumber) {
                                    allUniqueInsect = false;
                                }
                            }
                        }
                    }
                });
            });

            var allUniqueDisease = true;
            _.each(assignment.paDiseaseSamplings, function (e1, i1) {
                _.each(assignment.paDiseaseSamplings, function (e2, i2) {
                    if (i1 != i2 && e1.diseaseId == e2.diseaseId) {
                        allUniqueDisease = false;
                    }
                });
            });

            if (!allUniqueInsect) {
                Notification.error(gettextCatalog.getString("Insects for P/A sampling not unique"));
                $scope.isLoading = false;
                return;
            }

            if (!allUniqueDisease) {
                Notification.error(gettextCatalog.getString("Diseases for P/A sampling not unique"));
                $scope.isLoading = false;
                return;
            }

            /////////////////////////////////////////////////////////////////////

            if (!allPointsHasActivity && !hasPAInsects && !hasPADiseases ) {
                Notification.error(gettextCatalog.getString("All crop monitor scout points must have at least one activity or one insect or disease for P/A sampling must be selected"));
                $scope.isLoading = false;
                return;
            }

            if (hasRegularInsects && hasPAInsects) {
                Notification.error(gettextCatalog.getString("Only one insect activity type (Regular or PA) must be in point"));
                $scope.isLoading = false;
                return;
            }

            if (hasRegularDiseases && hasPADiseases) {
                Notification.error(gettextCatalog.getString("Only one disease activity type (Regular or PA) must be in point"));
                $scope.isLoading = false;
                return;
            }

            //special check for crop moniotr before sowing
            if ($scope.addCMModel.futureCrop) {
                if (hasOnSowingOnlyActivity) {
                    Notification.error(gettextCatalog.getString("Task before sowing must have only regular insects and weeds and P/A insect activities"));
                    $scope.isLoading = false;
                    return;
                }

                if (!allScalesAreUtmost || !allPlantsNumberZero) {
                    Notification.error(gettextCatalog.getString("All insect P/A samplings must have utmost scale and zero plants number in task before sowing"));
                    $scope.isLoading = false;
                    return;
                }
            }

            //console.log(assignment);

            _apiService.saveAssignment(_view.getCurrentAccount(), assignment).then(
                () => {
                    $scope.isLoading = false;
                    Notification.success(_i18n.getString("task.notifications.saveAssignment.success"));
                    clearAddCropMonitor();
                    _v.change({set: {"addCropMonitor": undefined}});
                },
                () => {
                    $scope.isLoading = false;
                    Notification.error(_i18n.getString("task.notifications.saveAssignment.error"));
                }
            );
        };

        $scope.deleteCropMonitor = function() {

          $scope.isLoading = true;

          var $uibModalInstance = $uibModal.open({
            openedClass: 'modal-open modal-deleteCropMonitor',
            templateUrl: 'modal-deleteCropMonitor',
            controllerAs: 'vm',
            controller: function($uibModalInstance) {

              var vm = this;

              // Cancel
              vm.cancel = function() {
                $uibModalInstance.close('cancel');
              };

              // Delete
              vm.delete = function() {
                $uibModalInstance.close('confirm');
              };
            }
          });

          $uibModalInstance.result.then(function (msg) {
            if (msg == 'confirm') {
              _apiService.deleteCropMonitor(_view.getCurrentAccount(), $scope.task.id).then(success => {

                clearViewCropMonitor();
                _v.change({set: {viewCropMonitor: undefined}});
                $rootScope.$broadcast('_view:viewPhotoNoteChanged');

                Notification.success(gettextCatalog.getString('Task was deleted'));
                $scope.isLoading = false;

              }, error => {
                $scope.isLoading = false;
                Notification.error(gettextCatalog.getString('Task was not deleted'));
              });
            }else {
              $scope.isLoading = false;
            }
          });

          $uibModalInstance.closed.then(function (msg) {
            $scope.isLoading = false;
          });
        };
    }
).filter('cropMonitorTaskName', function () {
    return function (item) {
        let data = [],
            propsOrder = ['type', 'date', 'pointsAmount'];

        _.each(propsOrder, p => {
            if (item[p] != null) {
                data.push(item[p]);
            }
        });

        return data.join('-');
    };
});